  var webengage;!function(w,e,b,n,g){function o(e,t){e[t[t.length-1]]=function(){r.__queue.push([t.join("."),
    arguments])
}
} var i, s, r = w[b], z = " ", l = "init options track screen onReady".split(z), a = "feedback survey notification".split(z), c = "options render clear abort".split(z), p = "Open Close Submit Complete View Click".split(z), u = "identify login logout setAttribute".split(z); if (!r || !r.__v) { for (w[b] = r = { __queue: [], __v: "6.0", user: {} }, i = 0; i < l.length; i++)o(r, [l[i]]); for (i = 0; i < a.length; i++) { for (r[a[i]] = {}, s = 0; s < c.length; s++)o(r[a[i]], [a[i], c[s]]); for (s = 0; s < p.length; s++)o(r[a[i]], [a[i], "on" + p[s]]) } for (i = 0; i < u.length; i++)o(r.user, ["user", u[i]]); setTimeout(function () { var f = e.createElement("script"), d = e.getElementById("_webengage_script_tag"); f.type = "text/javascript", f.async = !0, f.src = ("https:" == e.location.protocol ? "https://widgets.in.webengage.com" : "http://widgets.in.webengage.com") + "/js/webengage-min-v-6.0.js", d.parentNode.insertBefore(f, d) }) }
}(window, document, "webengage"); webengage.init("in~58adcc91");

function searchApartment1(param){
  webengage.track("Search Button clicked", {
    "Search by category": 'Apartment'
  });
}


function searchDesign(param) {
  webengage.track("Search Button clicked", {
    "Search by category": 'Design',
  });
}


function clickCategory(param) {
  webengage.track("Category clicked", {
    "Category name": param,
  });
}


  function setAttribute1(param1, param2, param3, param4){
    webengage.user.login(param3);
    webengage.user.setAttribute('we_first_name', param1);
    webengage.user.setAttribute('we_phone', param2);
    webengage.user.setAttribute('we_email', param3);
    webengage.user.setAttribute('title', 'website-get_your_estimate');
    webengage.track("Get Your Estimate", {
      "Category": 'Get Your Estimate',
      'lead_id': param4
    });
  }

  function searchApartment(param1,param2){
    webengage.track("Search your apartment", {
      "Select City": param1,
      "Select Apartment": param2,
    });
  }

function buildSpace(param1, param2, param3,param4) {
  webengage.track("Build your Space Clicked", {
    "Select the space": param1,
    "Carpet Area": param2,
    "Apartment name": param3,
    "Pincode": param4,
  });
}

function personaliedDesign(param1, param2) {
  webengage.track("Personalized Design", {
    "Lifestage": param1,
    "Theme": param2
  });
}

function personalDetails(param1, param2, param3, param4) {
  webengage.track("Personal Details", {
    "Name": param1,
    "Number": param2,
    "Purpose of Property": param3,
    "Email": param4,
  });
}

function packageSelected(param) {
  webengage.track("Package Selected", {
    "Package Type": param
  });
}

function filterSpace(param1, param2, param3, param4) {
  webengage.track("Filter Space", {
    "Space Type": param1,
    "Life Stages": param2,
    "Theme": param3,
    "Price Range": param4
  });
}

function apartmentClicked1(param1, param2, param3) {
  webengage.track("Project clicked", {
    "Project Name": param1,
    "Theme": param2,
    "Life Stages": param3,
  });
}

function apartmentViewed(param1, param2, param3,param4) {
  webengage.track("Apartments Viewed", {
    "Name": param1,
    "Developed By": param2,
    "Area": param3,
    "Layout Format": param4
  });
}

function designpersondetail(param1, param2, param3, param4, param5) {
  webengage.track("Design Personal Detail", {
    "Full Name": param1,
    "Mobile Number": param2,
    "Email Id": param3,
    "scope of work": param4,
    "pincode": param5
  });
}


function getthisDesign(param1, param2, param3) {
  webengage.track("Get this design", {
    "Space Type": param1,
    "Lifestage": param2,
    "EMI Starts from ": param3
  });
}

function propertyDetail(param1, param2, param3) {
  webengage.track("Design Property Details", {
    "Property Name": param1,
    "Type of Accomodation": param2,
    "Home Value": param3,
    "Floor Plan": 'No'
  });
}

function possesionDetail(param1, param2, param3, param4) {
  webengage.track("Design Possession details", {
    "House detail": param1,
    "possession detail": param2,
    "Start project date": param3,
    "Budget": param4
  });
}

function loanDetail(param1, param2, param3) {
  webengage.track("Design Loan details", {
    "Existing home loan": param1,
    "purpose of property": param2,
    "comment box": param3
  });
}

function whatsappClick() {
  webengage.track("Whatsapp clicked", {
  });
}

function fbClick() {
  webengage.track("Social Media", {
    "Social media platform": 'facebook',
  });
}

function igClick() {
  webengage.track("Social Media", {
    "Social media platform": 'Instagram',
  });
}

function liClick() {
  webengage.track("Social Media", {
    "Social media platform": 'Linkedin',
  });
}

function piClick() {
  webengage.track("Social Media", {
    "Social media platform": 'Pinterest',
  });
}

function sessionstart() {
  // webengage.track("Session_started", {
  //   "user_session_started"
  //   "Device": param2,
  // }
  webengage.user_session_started();
  // );
}
